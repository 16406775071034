.UpdateComp {
  
}

.UpdateComp .update-btn {
  width: 40px;
  height: 40px;
  background: var(--color-green);
  border-radius: 50%;
  position: relative;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.5s cubic-bezier(.09,.73,.37,.99);
  cursor: pointer;
  user-select: none;
}

.UpdateComp .update-btn:hover {
  transform: scale(1.05);
}

.UpdateComp .update-btn:active {
  transform: scale(0.95);
}

.UpdateComp .update-btn .file {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
}

@media screen and (max-width: 545px) {
  .UpdateComp .update-btn {
    right: 0;
  }
}