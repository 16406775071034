.SearchComp {
  position: relative;
  max-width: 545px;
  margin: -3rem auto 1rem;
}

.SearchComp .search-field {
  width: 100%;
  height: 60px;
  display: block;
  border-radius: 5px;
  background: #fff;
  border: none;
  box-shadow: 0 5px 20px rgba(0,0,0,0.16);
  padding: 1rem;
  text-align: center;
  font-size: 1.7rem;
  font-family: inherit;
  color: var(--color-chalk);
}

.SearchComp .search-btn {
  position: absolute;
  background: none;
  border: none;
  width: 60px;
  height: 100%;
  right: 0;
  top: 0;
  padding: 0;
  padding-top: 2px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(255,255,255,0), #fff 30%);
}

.SearchComp .search-btn img {
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.SearchComp .search-btn:hover img {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 545px) {
  .SearchComp .search-field {
    border-radius: 0;
  }
}