.SuccessPopupComp {
  width: 100%;
  height: 100%;
  z-index: 90;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-chalk-60);
  opacity: 0;
  pointer-events: none;
}

.SuccessPopupComp.open {
  opacity: 1;
  pointer-events: all;
}

.SuccessPopupComp.open .success-popup {
  animation: fade-in-scale 0.5s var(--easing-natural) forwards;
  animation-delay: 0.1s;
}

.SuccessPopupComp .success-popup {
  position: relative;
  width: 100%;
  max-width: 260px;
  min-height: 200px;
  background: #fff;
  border-radius: 5px;
  padding: 28px;
  text-align: center;
  opacity: 0;
}

.SuccessPopupComp .success-popup .success-icon {
  width: 87px;
  height: 87px;
  display: block;
  margin: 0 auto;
}

.SuccessPopupComp .success-popup .success-title {
  font-weight: normal;
  margin: 14px 0;
  text-align: center;
  font-size: 2rem;
  color: var(--color-green);
}

.SuccessPopupComp .success-popup .success-text {
  text-align: center;
  font-size: 1.3rem;
}
