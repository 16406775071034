*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

html, body {
  font-size: 10px;
}

body {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

hr {
  border: none;
  height: 1px;
  background: var(--color-chalk-15);
  margin: 30px 0;
}

.container {
  max-width: 786px;
  margin: 0 auto;
  padding: 0 10px;
}

.bold-green {
  color: var(--color-green);
}

@keyframes fade-in-scale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}